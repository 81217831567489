import styled from "@emotion/styled";
import Container from "@src/components/Container";
import { Column, Stack } from "@src/components/extended";
import { FaqItem, FaqList } from "@src/ui/FAQ";
import React, { useContext, useState } from "react";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { useTranslations, withProvider } from "@src/components/partials/districts-2025/DistrictProvider";
import TopNav from "@src/components/partials/districts-2025/TopNav";
import getLocale from "@src/utils/getLocale";
import DistrictsOpenGraph from "@src/www/districts/_assets/districts-opengraph.png";
import SEO from "@src/components/SEO";
import { useLocation } from "@reach/router";

const HeroTitle = styled.h1`
  color: ${(t) => t.theme.__new.colors.contentPrimary};
  leading-trim: both;
  text-edge: cap;
  font-family: "HafferXH";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
`;

const HeroParagraph = styled.p`
  color: ${(t) => t.theme.__new.colors.contentPrimary};
  font-family: "HafferXH";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  letter-spacing: 0.2px;
`;

const ContainerWrapper = styled.div`
  background-image: linear-gradient(180deg, rgba(235, 224, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 88%);
  background-repeat: no-repeat;
  padding-bottom: 48px;
`;

const useGetFAQList = () => {
  const t = useTranslations();
  const items = t.questions_faq.value;

  return items as unknown as FaqItem[];
};

const FrequentQuestions = ({ pageContext }) => {
  const list = useGetFAQList();
  const t = useContext(TranslationContext);
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const [utmContent, setUtmContent] = useState("");

  const openCalendly = (content: string) => {
    setUtmContent(content);
    setCalendlyOpen(true);
  };

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const utmCampaign = params.utm_campaign ?? "districtsPage";

  const userLanguage = getLocale(pageContext);
  const noIndex = ["en", "en-us"].includes(userLanguage) ? false : true;

  return (
    <ContainerWrapper>
      <SEO
        noindex={noIndex}
        title="Frequent Questions"
        image={DistrictsOpenGraph}
        description="Top ClassDojo for Districts Questions"
      />
      <TopNav
        calendlyOpen={calendlyOpen}
        setCalendlyOpen={setCalendlyOpen}
        onOpenCalendly={openCalendly}
        utmCampaign={utmCampaign}
        utmContent={utmContent}
      />
      <Container css={{ paddingTop: 48 }}>
        <Stack flexDirection={["column", "row"]} justifyContent="space-between">
          <Column width={["100%", "33%"]}>
            <HeroTitle>{t.translate("directus.page_districts_2025.questions_hero_title")}</HeroTitle>
            <HeroParagraph>{t.translate("directus.page_districts_2025.questions_hero_text")}</HeroParagraph>
          </Column>
          <Column flex={1} alignItems="flex-end">
            <FaqList faqs={list} />
          </Column>
        </Stack>
      </Container>
    </ContainerWrapper>
  );
};

export default withProvider(FrequentQuestions);
