import { forEach } from "lodash";

export type RepeaterWrapper<T> = { __type: "repeater"; value: T[] };

export interface RepeaterItem {
  [key: string]: string | RepeaterWrapper<RepeaterItem>;
}

export type Repeater = { [key: string]: string | Repeater };

export function transformRepeater(repeaterObject: Repeater): RepeaterWrapper<RepeaterItem> {
  const repeaterWrapper: RepeaterWrapper<RepeaterItem> = { __type: "repeater", value: [] };

  forEach(repeaterObject, (value, key) => {
    const [_x, field, id] = key.match(/(.*)_(\d)/);
    const idx = parseInt(id, 10) - 1;

    if (typeof value === "object") {
      repeaterWrapper.value[idx][field] = transformRepeater(value);
    } else if (typeof value === "string") {
      repeaterWrapper.value[idx] ||= {};
      repeaterWrapper.value[idx][field] = value;
    }
  });

  return repeaterWrapper;
}
