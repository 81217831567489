import { forEach } from "lodash";
import { GQLImage } from "../GQLImage";
import { Images } from "./PageProvider";

const fakeImage = () => "https://placehold.co/600x400";

export const useLoadImages = <T extends Images, K extends readonly string[]>(assets: T, values: K) => {
  const images: Record<string, GQLImage> = {};

  forEach(values, (k) => {
    if (!assets) return;
    const image = assets[k];
    if (image) images[k] = image;
    else images[k] = { publicURL: fakeImage() };
  });

  return images as Record<K[number], GQLImage>;
};
