import React, { ReactNode } from "react";
import { PageProvider } from "@src/components/page-provider/PageProvider";
import { useLoadTranslations } from "@src/components/page-provider";
// import { TRANSLATIONS, TranslationType } from "./translations";
import { useTranslations as useOriginalTranslations } from "@src/components/page-provider/usePage";
import data from "@src/assets/language/en/website2.website.json";
import { transformRepeater } from "@src/components/page-provider/transformRepeater";

export const TRANSLATIONS = {
  ...data.directus.page_districts_2025,
  districtwide_cards: transformRepeater(data.directus.page_districts_2025.districtwide_cards),
  questions_faq: transformRepeater(data.directus.page_districts_2025.questions_faq),
  benefits_repeater: transformRepeater(data.directus.page_districts_2025.benefits_repeater),
  partners_repeater: transformRepeater(data.directus.page_districts_2025.partners_repeater),
};

export type TranslationType = typeof TRANSLATIONS;

export const Provider = ({ children }: { children: ReactNode }) => {
  const t = useLoadTranslations("directus.page_districts_2025", TRANSLATIONS);

  return (
    <PageProvider translations={t} images={{}}>
      {children}
    </PageProvider>
  );
};

export const withProvider = <T,>(Component: React.FC<T>) => {
  const Wrapper = (props: T) => (
    <Provider>
      <Component {...(props as any)} />
    </Provider>
  );

  return Wrapper;
};

const useTranslations = useOriginalTranslations as typeof useOriginalTranslations<TranslationType>;

export { useTranslations };
