import { reduce, map, isArray, endsWith, forEach, isObject, isString } from "lodash";
import { useContext } from "react";
import { TranslationContext, TranslationType } from "../translation/TranslationContext";
import { TranslationKey, TranslationObject, Translations, TranslationsValue } from "./PageProvider";
import { RepeaterItem, RepeaterWrapper } from "./transformRepeater";

export interface RepeaterTranslation {
  [key: string]: TranslationType | RepeaterWrapper<RepeaterTranslation>;
}

function isRepetear(v: TranslationsValue, k: string): v is RepeaterWrapper<RepeaterItem> {
  return endsWith(k, "_repeater") || ((isObject(v) || isArray(v)) && "__type" in v && v.__type === "repeater");
}

export const useLoadTranslations = <T extends Translations>(prefix: string, values: T) => {
  const t = useContext(TranslationContext);

  const getTranslation = (key: string, fallback: string) => {
    const translation = t.translate(`${prefix}.${key}`);

    if (translation) {
      return translation;
    }

    return fallback;
  };

  const loadArray = (v: TranslationObject[], parent: TranslationKey) => {
    return map<TranslationObject>(v, (key, collection) => getTranslation(`${parent}_${key}`, collection[key]));
  };

  const loadString = (_v: TranslationsValue, k: TranslationKey) => {
    return getTranslation(k, values[k] as string);
  };

  const loadRepetear = (
    repeater: RepeaterWrapper<RepeaterItem>,
    parent: TranslationKey,
  ): RepeaterWrapper<RepeaterTranslation> => {
    const all = map(repeater.value, (value, idx) => {
      const entries: RepeaterTranslation = {};
      forEach(value, (_val, key) => {
        const val = repeater.value[idx][key];

        if (isObject(val)) {
          entries[key] = loadRepetear(val, `${parent}.${key}_${idx + 1}`);
          return;
        } else if (isString(val)) {
          entries[key] = getTranslation(`${parent}.${key}_${idx + 1}`, val);
        } else {
          throw new Error("Unexpected value in repeater");
        }
        // if (isArray(val)) {
        //   entries[key] = loadRepetear(val, `${parent}.${key}_${idx + 1}`);
        //   return;
        // }
      });
      return entries;
    });

    return { __type: "repeater", value: all };
  };

  const load = (v: TranslationsValue, k: TranslationKey) => {
    if (isRepetear(v, k)) {
      return loadRepetear(v, k);
    } else if (isArray(v)) {
      return loadArray(v, k);
    } else {
      return loadString(v, k);
    }
  };

  const valuesTranslated = reduce(
    values,
    (acc, v, k) => {
      acc[k] = load(v, k);
      return acc;
    },
    {},
  );

  return valuesTranslated as T;
};
